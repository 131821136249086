import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignJustify, faAsterisk, faCheck, faInfoCircle, faPlusCircle, faReply,
         faSwimmer, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { formatted_time, region_variant, team_swimmers, relay_team_record, relay_age, relay_swimmer } from '../util/format'
import { getResource, putResource, deleteResource } from '../util/resource'

//const REGIONS = ['FINA', 'USMS', 'ZONE', 'OREG']


/* URL /team/:team_id/event/:event_id
   Display the Selected Relay Teams for a meet Event
*/
function TeamEvent() {
    const { team_id, event_id } = useParams()
    const [team, setTeam] = useState({})
    const [event, setEvent] = useState({})
    const [teams, setTeams] = useState()
    const [records, setRecords] = useState()
    const [showModal, setShowModal] = useState(false)
    const [show, setShow] = useState({})

    function closeModal() { setShowModal(false) }
    function remove_event() { alert('remove event') }
        
    useEffect(() => { getResource(`/team/${team_id}`).then(team => {
        team_swimmers(team)
        setTeam(team)
        setEvent(team.events[event_id])
        setShow({})
    }) }, [team_id, event_id])

    // generate all the possible relay teams whenever the list of selected relay teams change
    useEffect(() => {
        if (team.relays) getResource(`/team/${team_id}/event/${event_id}`)
                                    .then(event => setTeams(event.teams))
    }, [team.relays, team_id, event_id])

    useEffect(() => {
        if (team.course && event)
            getResource(`/records?course=${team.course}&style=R&gender=${event.gender}`)
                .then(records => {
                    const relay_records = {}
                    for (const record of records) {
                        const holders = JSON.parse(record.holders)
                        for (const h of holders) {
                            if (h[4] !== event.distance || h[3][0] !== event.relay)
                                continue
                            const by_age_group = relay_records[h[2]] || []
                            const date = h[5].toString()
                            by_age_group.push([h[0], h[6],
                                              [date.slice(0,4), date.slice(4,6), date.slice(6)].join('-'),
                                              h[7], [h[8], h[9], h[10], h[11]].join(', ')])
                            relay_records[h[2]] = by_age_group
                        }
                    }
                    setRecords(relay_records)
                })
        return () => setRecords(null)   // reset records when the event changes
    }, [team.course, event])

    if (!team.swimmers)  return <ProgressBar animated now={100}/>

    const distance = (event.distance / 4) + ' '
    const strokes = (event.relay === 'M')
                    ? ['Back', 'Breast', 'Fly', 'Free']
                    : ['Free', 'Free', 'Free', 'Free']

    let age_groups = []
    if (records && teams) {
        for (const age_group in teams) {
            const age_group_records = records[age_group]
            if (!age_group_records) {
                console.log('Missing age_group_records', age_group)
                continue
            }
            let r = 0
            let record = age_group_records[r]
            for (const relay_team of teams[age_group]) {
                while (record && relay_team[0] > record[1])
                    if (++r < age_group_records.length)
                        record = age_group_records[r]
                    else
                        record = null

                if (record) {   // color-code the relay for this region
                    relay_team.region = record[0]
                    relay_team.record = record[1]
                    relay_team.holder = record[3]
                } /* else if (r < REGIONS.length)   // no record for this region yet
                    relay_team.region = REGIONS[r] */
            }
        }
        age_groups = Object.keys(teams).sort((a,b) => parseInt(a)-parseInt(b))
    }

    function select_team(relay_team, age_group) {
        var params = {age_group, time: relay_team[0], swimmers: relay_team.slice(1,5),
                      region: relay_team.region, record: relay_team.record, holder: relay_team.holder}
        putResource(`/team/${team_id}/event/${event_id}`, params).then(new_team => {
            setTeam({...team, relays: new_team.relays})
        })
    }

    function remove_team(index) {
        deleteResource(`/team/${team_id}/event/${event_id}?index=${index}`).then(new_team => {
            setTeam({...team, relays: new_team.relays})
        })
    }
  
    function AgeGroup({ age_group }) {
        return (
            <Card bg="light" border="primary" className="mb-4">
                <Button style={{ cursor: "pointer" }}
                    onClick={ () => setShow({...show, [age_group]: !show[age_group]}) }>
                    Age Group { age_group } <Badge variant="light">{ teams[age_group].length } teams</Badge>
                </Button>
                { show[age_group] &&
                    <Card.Body>
                        <Card className="mb-3"><Card.Body>Records:
                            { records[age_group].map(record =>
                            <Alert variant={ region_variant(record[0]) } key={record[0]} className="mb-0">
                                <Badge variant={ region_variant(record[0]) }>{ record[0] }</Badge>
                                <b className="ms-2 me-2">{ formatted_time(record[1]) }</b>
                                { record[2] } <br className="d-block d-sm-none" />{ record[3] }<br/>{ record[4] }
                            </Alert>
                            ) }
                        </Card.Body></Card>
                        { teams[age_group].slice(0,100).map((relay_team, i) =>
                            <Alert variant={ region_variant(relay_team.region) } key={i}>
                                <Button variant="info" size="sm" className='me-3' onClick={ () => select_team(relay_team, age_group) }>
                                    <FontAwesomeIcon icon={faCheck}/>
                                </Button>
                                {/*
                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true" ng-click="select_team(team, age_group)" ng-if="$parent.user"><i className="fa fa-check"></i></button>
                                */}
                                { formatted_time(relay_team[0]) }
                                { relay_age(relay_team.slice(1,5), team.course) }
                                { strokes.map((stroke, s) => <div key={s}>{ relay_swimmer(relay_team[s+1], distance + stroke) }</div>) }
                            </Alert>
                        ) }
                    </Card.Body>
                }
            </Card>
        )
    }
    
    return (
        <Container>
            <p>{ team.meet_dates }</p>
            <h4>
                <Link to={ `/meet/${team.meet_id}` } className="me-2">
                    <Button variant="info" size="sm"><FontAwesomeIcon icon={faReply}/></Button>
                </Link>
                { team.meet_name } <Badge variant="primary">{ team.course }</Badge>
            </h4>

            <h3>{ event.name }</h3>
            <p ng-if="event.date">{ new Date(event.date+'T00:00:00').toDateString() }</p>

            <Modal size="sm" show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove this Event?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={remove_event}>OK</Button>
                </Modal.Body>
            </Modal>

            <ButtonGroup>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle>
                        <FontAwesomeIcon icon={faAsterisk}/> Events
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        { Object.entries(team.events).filter(([event_id, event]) => event.relay)
                                .map(([event_id, event]) =>
                            <Dropdown.Item as={Link} to={`/team/${team.id}/event/${event_id}`} key={event_id}>
                                { event.name }
                            </Dropdown.Item>
                        ) }
                        { team.is_admin && <Dropdown.Divider /> }
                        { team.is_admin &&
                            <Dropdown.Item as={Link} to={`/meet/${team.meet_id}/event`}>
                                <FontAwesomeIcon icon={faPlusCircle}/> Add Event
                            </Dropdown.Item>
                        }
                        { team.is_admin &&
                            <Dropdown.Item onClick={ () => setShowModal(true) }>
                                <FontAwesomeIcon icon={faTimesCircle}/> Remove Event
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <Link to={ `/team/${team_id}/swimmers` }>
                    <Button variant="primary" className='ms-1'>
                        <FontAwesomeIcon icon={faSwimmer}/> Swimmers
                    </Button>
                </Link>
                { team.is_admin &&
                <Link to={ `/event/${team.meet_id}/${event_id}/info` }>
                    <Button variant="primary" className='ms-1'>
                        <FontAwesomeIcon icon={faInfoCircle}/> Event Info
                    </Button>
                </Link>
                }
            </ButtonGroup>

            { (!team.relays) && <div>Select relay teams from the age groups</div> }

            <Card className="mb-4">
                <Card.Header>
                    <FontAwesomeIcon icon={faAlignJustify}/> Selected Relay Teams
                </Card.Header>
                <Card.Body>
                    { team.relays[event_id] && team.relays[event_id].map((relay_team, index) =>
                        <Alert variant={region_variant(relay_team.region)} key={index}>
                            { team.is_Admin &&
                            <Button variant="info" size="sm" className="me-2"
                                    onClick={ () => remove_team(index) }>
                                <FontAwesomeIcon icon={faTimes}/>
                            </Button> }
                            { relay_team.age_group }
                            { relay_age(relay_team.swimmers) }
                            { formatted_time(relay_team.time) }
                            { relay_team_record(relay_team) }
                            <br/>
                                { relay_team.swimmers.map((swimmer, s) =>
                                <span ng-class="{excluded: swimmer_excluded[relay_team.swimmers[s]]}" style={{ display: 'block' }} key={s}>
                                    { relay_swimmer(swimmer, distance + strokes[s]) }
                                </span>
                            ) }
                        </Alert>
                    )}
                </Card.Body>
            </Card>

            { !teams &&
                <div className="alert alert-info">
                    Generating teams
                    <ProgressBar animated now={100}/>
                </div>
            }

            { age_groups.map(age_group => <AgeGroup age_group={age_group} key={age_group} />)}
        </Container>
    )
}

export default TeamEvent