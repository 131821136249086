import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { formatted_time, time_since } from '../util/format'
import { getResource } from '../util/resource'
import usms_ico from './icons/usms.ico'


const ALL = 'All'
const BEST = 'Best'
const filters = {
    age_group: ALL,
    course: ALL,
    event: ALL,
    times: BEST,
}

function filter_times(time) {
    if (filters.age_group !== ALL && filters.age_group !== time.age_group)  return false
    if (filters.course !== ALL && filters.course !== time.course)  return false
    if (filters.event !== ALL && filters.event !== time.event)  return false
    if (filters.times !== ALL && !time.best)  return false
    return true
}

function record(records, region, age_group, event) {
    return formatted_time(records[region+" "+age_group+" "+event])
}

function percent(records, region, age_group, event, decimal_time) {
    if (!region)    return ''
    const number = (decimal_time * 100.0 / records[region+" "+age_group+" "+event]) - 100.0
    return isNaN(number) ? '' : number.toFixed(2) + '%'
}


function SwimmerTimes() {
    const { usms_id } = useParams()
    const [loading, setLoading] = useState(false)
    const [swimmer, setSwimmer] = useState()
    const [times, setTimes] = useState([])
    const [, setPulldown] = useState(filters)
    const [pulldowns, setPulldowns] = useState({ age_groups: [], courses: [], events: [] })
    const [compare, setCompare] = useState({ regions: [], records: {} })
    const [region, setRegion] = useState()

    useEffect(() => { getResource('/swimmer/'+usms_id).then(setSwimmer) }, [usms_id])

    function getTimes() {
        setLoading(true)
        getResource('/swimmer/'+usms_id+'/times').then(times => {
            const age_groups = [ALL]
            const courses = [ALL]
            const events = [ALL]
            const best_set = {}
            times.usms_times = JSON.parse(times.usms_times)
            for (const time of times.usms_times) {
                if (!age_groups.includes(time.age_group))
                    age_groups.push(time.age_group)
                if (!courses.includes(time.course))
                    courses.push(time.course)
                if (!events.includes(time.event))
                    events.push(time.event)
                const best = time.course + time.age_group + time.event
                if (!(best in best_set))
                    best_set[best] = time.best = true
            }
            //age_groups.sort((a, b) => parseInt(b) - parseInt(a))
            if (age_groups.length > 1)
                filters.age_group = age_groups[1]
            if (courses.length > 1)
                filters.course = courses[1]
            setTimes(times)
            setPulldowns({ age_groups, courses, events })
            setLoading(false)
        })
    }
    useEffect(getTimes, [usms_id])

    function setContext(key, value) {
        filters[key] = value
        setPulldown({[key]: value})
        if (key === 'course') getRecords()
    }

    function getRecords() {
        if (!filters.course || !swimmer)    return 
        //setLoading(true)
        var gender = swimmer.sex || 'M'
        //if (gender === 'F')  gender = 'W'
        //else if (gender !== 'M')  gender = 'M'
        getResource('/records?course='+filters.course+'&gender='+gender).then(course_records => {
            const records = {}
            const regions = []
            for (const region of course_records)
                for (const r of JSON.parse(region.holders)) {
                    records[r[0]+" "+r[2]+" "+r[4]+" "+r[3]] = r[6]
                    if (!regions.includes(r[0]))
                        regions.push(r[0])
                }
            //setContext('records', records)
            setCompare({ regions, records })
            //setLoading(false)
        })
    }
    useEffect(getRecords, [swimmer])

    return (
        <Container>
            { swimmer && times &&
            <div>
                <legend style={{ borderBottom: '1px solid #e5e5e5'}}>
                    <FontAwesomeIcon icon={faStar} className='gold me-2'/>
                    { swimmer.name } - { swimmer.group ? swimmer.group : swimmer.club }
                </legend>
                <Row>
                    <a rel='noopener noreferrer' target='_blank'
                        href={'https://www.usms.org/comp/meets/indresults.php?SwimmerID='+usms_id}>
                        <Button variant="light">
                            <img src={usms_ico} alt="USMS" className="me-2"/>
                            USMS Individual Meet Results <Badge variant="info">{ usms_id }</Badge>
                        </Button>
                    </a>
                    <small className='ms-3'>
                        <i> Retrieved { time_since(Date.parse(times.as_of)) + ' ago' }</i>
                    </small>
                </Row>
            </div>
            }

            { pulldowns.age_groups &&
                <Row className='mt-3 mb-5'>
                    <Col xs={4} sm={3} md={2}>
                        <b>Ages</b>
                        <Form.Select value={filters.age_group} id='ages'
                            onChange={ e => setContext('age_group', e.target.value) }>
                            { pulldowns.age_groups.map(ag => <option key={ag}>{ag}</option>) }
                        </Form.Select>
                    </Col>
                    <Col xs={4} sm={3} md={2}>
                        <b>Course</b>
                        <Form.Select value={filters.course} id='course'
                            onChange={ e => setContext('course', e.target.value) }>
                            { pulldowns.courses.map(course => <option key={course}>{course}</option>) }
                        </Form.Select>
                    </Col>
                    <Col xs={4} sm={3} md={2}>
                        <b>Event</b>
                        <Form.Select value={filters.event} id='event'
                            onChange={ e => setContext('event', e.target.value) }>
                            { pulldowns.events.map(event => <option key={event}>{event}</option>) }
                        </Form.Select>
                    </Col>
                    <Col xs={4} sm={3} md={2}>
                        <b>Times</b>
                        <Form.Select value={filters.times} id='times'
                                onChange={ e => setContext('times', e.target.value) }>
                            <option>{ ALL }</option>
                            <option>{ BEST }</option>
                        </Form.Select>
                    </Col>
                    <Col xs={4} sm={3} md={2}>
                        <b>Compare</b>
                        <Form.Select value={compare.region}
                                onChange={ e => setRegion(e.target.value) }>
                            <option value=''>None</option>
                            { compare.regions.map(region => <option key={region}>{region}</option>) }
                        </Form.Select>
                    </Col>
                </Row>
            }

            { loading && <ProgressBar animated now={100}/> }

            { times && times.usms_times && times.usms_times.length === 0 &&
<div id="not_found" className="alert alert-error" style={{ display: 'none' }}>No times were found</div>
            }

            { times && times.usms_times &&
            <table id="times" className="table table-striped" style={{ background: 'white', width: 'auto' }}>
                <thead>
                    <tr className="blue-gradient">
                        <th className="text-center">Date</th>
                        <th className="text-center">Age</th>
                        <th className="text-center">Course</th>
                        <th className="text-right">Event</th>
                        <th className="text-right">Time</th>
                        <th className="text-right">{ region }</th>
                        <th className="text-center">{ region && '%' }</th>
                    </tr>
                </thead>
                <tbody>
                    { times.usms_times.filter(filter_times).map((time, i) =>
                        <tr key={ i }>
                            <td className="text-center">{ time.date }</td>
                            <td className="text-center">{ time.age_group }</td>
                            <td className="text-center">{ time.course }</td>
                            <td className="text-right">{ time.event }</td>
                            <td className="text-right">{ formatted_time(time.decimal_time) }</td>
                            <td className="text-right">{ record(compare.records, region, time.age_group, time.event) }</td>
                            <td className="text-right">
                                <Badge>{ percent(compare.records, region, time.age_group, time.event, time.decimal_time) }</Badge>
                            </td>
                        </tr>
                    ) }
                </tbody>
            </table>
            }
        </Container>
    )
}

export default SwimmerTimes