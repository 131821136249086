import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faReply, faStar } from '@fortawesome/free-solid-svg-icons'

import usms_ico from './icons/usms.ico'
import { formatted_time } from '../util/format'
import { getResource, putResource } from '../util/resource'

const RELAY_STROKES = ['50 Fly', '50 Back', '50 Breast', '50 Free',
                       '100 Fly', '100 Back', '100 Breast', '100 Free', '200 Free']
const MINUTES=[0,1,2,3,4,5,6,7,8,9]
const SECONDS=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,
    20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,
    40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
const TENTHS=[0,1,2,3,4,5,6,7,8,9]

function TeamSwimmer() {
    const { team_id, index } = useParams()
    const [team, setTeam] = useState()
    const [events, setEvents] = useState()
    const [picker, setPicker] = useState(null)

    useEffect(() => {
        getResource(`/team/${team_id}`).then(team => {
            team.swimmer = team.swimmers[parseInt(index)]
            team.relay_events = Object.fromEntries(
                Object.entries(team.events).filter(([number, event]) => event.relay))
            setEvents(team.swimmer.events)
            setTeam(team)
        })
    }, [team_id, index])

    if (!team)  return <ProgressBar animated now={100}/>

    function update() {}
    function in_relay_event(number, value) {
        console.log('in_relay_event', number)
        console.log('value', value)
        events[number] = value
        setEvents({...events})
        putResource(`/team/${team_id}/swimmer/${index}`, { events })
    }

    function selectPicker(event) {
        let decimal_time = 0, minutes = 0, seconds = 0, tenths = 0
        if (team.swimmer.times)
            decimal_time = team.swimmer.times[event] || 0
        if (decimal_time) {
            seconds = Math.floor(decimal_time / 100)
            minutes = Math.floor(seconds / 60)
            seconds = seconds % 60
            tenths = Math.floor((decimal_time % 100) / 10)
        }
        setPicker({event, minutes, seconds, tenths})
    }

    function savePicker() {
        var decimal_time = (parseInt(picker.minutes) * 60 +
                            parseInt(picker.seconds)) * 100 +
                            parseInt(picker.tenths) * 10
        if (team.swimmer.times[picker.event] !== decimal_time) {
            if (decimal_time) team.swimmer.times[picker.event] = decimal_time
            else delete team.swimmer.times[picker.event]
            putResource(`/team/${team_id}/swimmer/${index}`, { times: team.swimmer.times })
        }
        setPicker(null)
    }

    return (
        <Container>
            <p>{ team.meet_dates }</p>
            <h4>
                <Link to={ `/team/${team.id}/swimmers` } className="me-2">
                    <Button variant="info" size="sm"><FontAwesomeIcon icon={faReply}/></Button>
                </Link>
                { team.name } <Badge variant="primary">{ team.course }</Badge>
            </h4>

            <Form>
                <legend>
                    <FontAwesomeIcon icon={faStar} className="gold me-2"/>{ team.swimmer.name }
                </legend>

                <div><label>Information for generating relay teams</label></div>
                <p>Make all changes here before generating relays</p>

                <Row>
                    <Col xs={12} sm={5}>
                        <Form.Group as={Row} controlId="usms_number">
                            <Form.Label column xs={4} sm={5}>USMS #</Form.Label>
                            <Form.Label column xs={3} sm={5} lg={4}>{team.swimmer.usms_id}</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId="gender">
                            <Form.Label column xs={4} sm={5}>Gender</Form.Label>
                            <Col xs={3} sm={5} md={4}>
                                <Form.Control as='select' value={team.swimmer.sex}
                                    onChange={ gender => update('gender', gender) }>
                                    <option value='M'>Male</option>
                                    <option value='F'>Female</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="age">
                            <Form.Label column xs={4} sm={5}>Age</Form.Label>
                            <Col xs={3} sm={5} md={3}>
                                <Form.Control type="number" value={team.swimmer.age}
                                    onChange={ age => update('age', age) }/>
                            </Col>
                        </Form.Group>

                        { RELAY_STROKES.map(event =>
                        <Form.Group as={Row} key={event} controlId={event}>
                            <Form.Label column xs={4} sm={5} onClick={ () => selectPicker(event) }>{ event }</Form.Label>
                            <Col xs={7}>
                                { picker?.event !== event && <>
                                <Button variant="secondary" onClick={ () => selectPicker(event) }>
                                    { formatted_time(team.swimmer.times[event]) || 'none' }</Button>
                                    {/* team.swimmer.times[event].source */} </>
                                }
                                { picker?.event === event &&
                                <table><tbody><tr>
                                    <td>
                                        <Form.Control as='select' value={picker.minutes}
                                            onChange={(e) => setPicker({...picker, minutes:e.target.value})}>
                                            {MINUTES.map(m => <option key={m}>{m}</option>)}
                                        </Form.Control>
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <Form.Control as='select' value={picker.seconds}
                                            onChange={(e) => setPicker({...picker, seconds:e.target.value})}>
                                            {SECONDS.map(s => <option key={s}>{s}</option>)}
                                        </Form.Control>
                                    </td>
                                    <td>.</td>
                                    <td>
                                        <Form.Control as='select' value={picker.tenths}
                                            onChange={(e) => setPicker({...picker, tenths:e.target.value})}>
                                            {TENTHS.map(t => <option key={t}>{t}</option>)}
                                        </Form.Control>
                                    </td>
                                    <td><Button onClick={ () => savePicker() }>
                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                    </Button></td>
                                </tr></tbody></table>
                                }
                            </Col>
                        </Form.Group>
                        ) }

                        <a rel='noopener noreferrer' target='_blank'
                            href={'https://www.usms.org/comp/meets/indresults.php?SwimmerID='+team.swimmer.usms_id}>
                            <Button variant="light">
                                <img src={usms_ico} alt="USMS" className="me-2"/>
                                USMS Individual Meet Results <Badge variant="info">{ team.swimmer.usms_id }</Badge>
                            </Button>
                        </a>
                    </Col>

                    <Col xs={12} sm={7}>
                        { Object.entries(team.relay_events).map(([number, event]) =>
                            <Form.Group key={number} as={Row}>
                                <Form.Label column xs={7} md={7}>{ event.name }</Form.Label>
                                <Col xs={5} md={5}>
                                    <ButtonGroup>
                                        <ToggleButton type='radio' name={number} checked={events[number] === true} onClick={ e => in_relay_event(number, true) } className="btn-relay-yes" >Yes</ToggleButton>
                                        <ToggleButton type='radio' name={number} checked={events[number] === false} onClick={ e => in_relay_event(number, false) } className="btn-relay-no">No</ToggleButton>
                                    </ButtonGroup>
                                </Col>
                            </Form.Group>
                        )}
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default TeamSwimmer