import firebaseApp from 'firebase/compat/app'
import 'firebase/compat/auth'

import { postResource, deleteResource } from './resource'


const config = {
    apiKey: "AIzaSyAbAWyE-Ccg_5c2bCFqU0P1Cjt6fQXHPaI",
    authDomain: "record-breakr.firebaseapp.com",
}
firebaseApp.initializeApp(config)

const auth = firebaseApp.auth()
auth.setPersistence(firebaseApp.auth.Auth.Persistence.SESSION)

function onIdTokenChanged(setUser) {
    auth.onIdTokenChanged(async user => {
        if (user) {
            const idToken = await user.getIdToken()
            await postResource('/session', { idToken })
        }
        setUser(user)
    })
}

async function logout() {
    await deleteResource('/session')
    auth.signOut()
}

export { onIdTokenChanged, logout }
