import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'

import Home from './components/Home'
import Meet from './components/Meet'
import MeetSwimmers from './components/MeetSwimmers'
import Meets from './components/Meets'
import MeetsUpcoming from './components/MeetsUpcoming'
import Team from './components/Team'
import TeamSwimmer from './components/TeamSwimmer'
import TeamSwimmers from './components/TeamSwimmers'
import TeamEvent from './components/TeamEvent'
import Records from './components/Records'
import Swimmers from './components/Swimmers'
import SwimmerTimes from './components/SwimmerTimes'
import Clubs from './components/Clubs'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import { onIdTokenChanged, logout } from './util/firebase'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFlag, faStar, faTrophy, faSignInAlt } from '@fortawesome/free-solid-svg-icons'


function Header({ user }) {
    const [showLogin, setShowLogin] = useState(false)

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                scopes: [ 'email' ],
            },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => { setShowLogin(false); return false }
        },
        tosUrl: '/terms',
        privacyPolicyUrl: '/privacy'
    }

    return (
        <Container>
        <Navbar expand="sm" className="brand-header">
            <Navbar.Brand className="brand">
                <Link to='/'>
                    <img src="/logo.png" alt="Record Break'r" />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
                <ButtonGroup size="sm">
                    <Link to="/records"><Button className="nav-tab blue-gradient">
                        <FontAwesomeIcon icon={faTrophy}/> Records
                    </Button></Link>
                    <Link to="/swimmers"><Button className="nav-tab blue-gradient">
                        <FontAwesomeIcon icon={faStar}/> Swimmers
                    </Button></Link>
                    <Link to="/clubs"><Button className="nav-tab blue-gradient">
                        <FontAwesomeIcon icon={faFlag}/> Clubs
                    </Button></Link>
                    <Link to="/meets"><Button className="nav-tab blue-gradient">
                        <FontAwesomeIcon icon={faCalendarAlt}/> Meets
                    </Button></Link>
                    { user ?
                        <Button className="nav-tab blue-gradient" onClick={logout} style={{ fontSize: '16px' }}>
                            <FontAwesomeIcon icon={faSignInAlt}/> Logout
                        </Button>
                    :
                        <Button className="nav-tab blue-gradient" onClick={() => setShowLogin(true)} style={{ fontSize: '16px' }}>
                            <FontAwesomeIcon icon={faSignInAlt}/> Login
                        </Button>
                    }
                </ButtonGroup>
            </Navbar.Collapse>

            <Modal show={showLogin} onHide={() => setShowLogin(false)}>
                <Modal.Header closeButton className="panel-rb">
                    <Modal.Title>
                        <FontAwesomeIcon icon={faSignInAlt} className="gold me-2"/>
                        Login to <img src="/logo.png" alt="Record Break'r" className="mb-2" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="panel-rb">
                    Sign in to create relay teams for meets.<br/>
                    <small>
                        Record Break'r will also highlight your Times, Records and Club.
                    </small>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </Modal.Body>
            </Modal>
        </Navbar>
        </Container>
    )
}

function App() {
    const [user, setUser] = useState()
    useEffect(() => onIdTokenChanged(setUser), [])

    return (
        <BrowserRouter>
            <Header user={user}/>
            <Routes>
                <Route path='/meets/upcoming' element={<MeetsUpcoming/>} />
                <Route path='/meets' element={<Meets/>} />
                <Route path='/meet/:meet_id/swimmers' element={<MeetSwimmers user={user}/>} />
                <Route path='/meet/:meet_id' element={<Meet/>} />
                <Route path='/team/:team_id/swimmers' element={<TeamSwimmers/>} />
                <Route path='/team/:team_id/swimmer/:index' element={<TeamSwimmer/>} />
                <Route path='/team/:team_id/event/:event_id' element={<TeamEvent/>} />
                <Route path='/team/:team_id' element={<Team/>} />
                <Route path='/records' element={<Records/>} />
                <Route path='/swimmers' element={<Swimmers/>} />
                <Route path='/swimmer/:usms_id/times' element={<SwimmerTimes/>} />
                <Route path='/clubs' element={<Clubs/>} />
                <Route path='/terms' element={<Terms/>} />
                <Route path='/privacy' element={<Privacy/>} />
                <Route path='/' element={<Home/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
