import React from 'react'

import Container from 'react-bootstrap/Container'


function Privacy() {
    return (
        <Container>
<h3>Digital Privacy Policy</h3>

<p><i>Effective July 1, 2013</i></p>

<p>This Digital Privacy Policy ("Privacy Policy") describes how we collect and use your information through a variety of digital means.
By accessing or using this website, mobile application or other Record Break'r product or service on any computer, mobile phone, tablet,
console or other device (collectively, "Device"), you consent to our Privacy Policy.
Record Break'r may modify this Privacy Policy at any time effective upon its posting.
Your continued use of our products and services constitutes your acceptance to this Privacy Policy and any updates.
This Privacy Policy is incorporated into, and is subject to, the&nbsp;<a href="#/info/terms">Terms of Use</a>.
</p>
<p>
While this Privacy Policy is intended to generally describe our privacy practices,
our goal is to also provide more detailed information at times when it's most meaningful to you.
Please look for these notices within our Record Break'r services.</p>

<p><span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
Collecting Information<br/></span><br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>What You Give Us</span><br/>
We collect information you give us or permit us to access.
Information may include, but is not limited to, your name, image, birth date, email address, gender,
social media information and profile, activity and performance information.<br/>
<br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>What We Collect From You</span><br/>
We may automatically collect information regarding your interaction with, and use of, our products and services.
Information we may collect includes, but is not limited to, your telephone number, Device identifier and hardware information,
IP address, browser type and language, cookie information, system type, whether you have enabling software to access certain features,
access times, referring website URLs, information about your purchases and other information about your interactions with us.<br/>
<br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>What We Collect From other Record Break'r Interactions and Third Parties</span><br/>
We may combine information you give us with other information from Record Break'r sources, transactions and communications. This may include, but is not limited to, information from Record Break'r stores, direct mail, catalogs, events, products and applications, or other Record Break'r interactions.&nbsp;&nbsp;We may also combine that information with data that is publicly available and data from third parties. We also collect information about gift recipients provided by the giver.&nbsp;&nbsp;<br/>
<br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Children's Privacy</span><br/>
We do not knowingly collect or solicit personal information from children under 13.<br/>
<br/></p>
<p><span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
Using Information<br/></span><br/>
We may use your information to do the following:<br/></p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Enable Your Use</span></p>
<ul>
<li>Enhance, customize and personalize your Record Break'r experiences and communications&nbsp;</li>
<li>Use Record Break'r features, such as running routes, race registrations, placing an order and other activities<br/></li>
</ul>
<p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Operate, Improve and Analyze</p>
<ul>
<li>Operate, provide, improve and maintain Record Break'r's products and services, including analyzing user behavior and trends</li>
<li>Send you administrative messages and other information about Record Break'r. Communicate with you about your purchase, account information or customer service<br/></li>
</ul>
<p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Provide and Conduct Marketing and Promotions</p>
<ul>
<li>Communicate with you about our products, services and events, and for other promotional purposes</li>
<li>Administer contests, promotions, events, surveys or other features</li>
<li>Display relevant marketing to you<br/></li>
</ul>
<p><span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
<br/>
Sharing Information</span></p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Service Providers</span><br/>
We may transfer your information to Record Break'r Family service providers to conduct our business.&nbsp;&nbsp;For example, they may handle credit card processing, shipping, data management, email distribution, market research, information analysis, and promotions management. We may also share your information to administer features (e.g. music download, race registration, or workout routine).&nbsp;&nbsp;&nbsp;</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
By Law or to Protect Rights</span><br/>
We may disclose information upon governmental request, in response to a court order, when required by law,
to enforce our policies, or to protect our or others' rights, property or safety.
We may share information to prevent illegal uses of Record Break'r's
products and services or violations of the <a href="#/info/terms">Terms of Use</a>,
or to defend ourselves against third-party claims.
We may also share information with companies assisting in fraud protection or investigation.</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Business Transfers</span><br/>
Your information may be transferred to a third party as a part of our business assets in a sale of a part or all of Record Break'r.&nbsp;&nbsp;If this should happen, notice will be provided by posting to the website or other form of communication.<br/>
<br/>
<br/></p>
<p><span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
Public Sharing</span><br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Default Sharing</span><br/>
When you join or use certain services, you agree to publicly share a basic amount of information, which may include your username, city location, and profile picture.&nbsp;&nbsp;</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Sharing You Choose</span><br/>
You may choose to share certain information.&nbsp;&nbsp;In order to participate in certain features, you may have to adjust your privacy settings and share more information. You may also choose to share your activity on other platforms, such as Facebook and Twitter. Please read the privacy policies of those platforms, because your Record Break'r activity published on those platforms will no longer be governed by Record Break'r's Privacy Policy.</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Record Break'r Sharing</span><br/>
Information that is publicly shared may be used by Record Break'r for promotional purposes.</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Protecting Information</span><br/>
Security Measures<br/>
We use a variety of security measures, including encryption and authentication tools, to help protect your information. We use secure servers when you place orders. All credit card information you supply is transmitted via Secure Socket Layer (SSL) technology and then encrypted within our databases.</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
No Guarantee</span><br/>
However, like other companies, Record Break'r cannot guarantee 100% the security or confidentiality of the information you provide to us.<br/></p>
<p><span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
<br/>
Collection Tools<br/></span><br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>How We Collect Information</span><br/>
Record Break'r automatically collects information within our products and services or stored by your browser or Device. We use a variety of methods to collect this information, including, but not limited to, cookies and pixel tags/web beacons ("Collection Tools").</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Turning off Collection Tools</span><br/>
If you turn off certain Collection Tools, you may not have access to many features that make your experience more efficient and some of our services and features may not function properly.</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Third Party Tracking</span><br/>
We also work with other companies who use tracking technologies to serve ads on our behalf across the Internet.
These companies may collect information about your interaction with us, including advertising.<br/>
<br/></p>
<p>&nbsp;</p>
<p><span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
Choices<br/></span><br/>
<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Modifying or Deleting Your Information</span><br/>
You can modify or delete your profile within certain Record Break'r services,
through your account.
Your information previously posted may still be publicly viewable.
Record Break'r may keep information and content in our backup files and archives.</p>
<p><span style={{ fontStyle: 'italic', fontWeight: 'bold' }}><br/>
Communication/Unsubscribe/Opt-Out</span><br/>
Regardless of the settings you select, Record Break'r
may send you service-related communications.
To unsubscribe and stop receiving messages, please change your settings or follow the instructions in the email,
text message, notification or other message type.
If you have unsubscribed from any Record Break'r communications,
due to production schedules, you may receive communications already in production.<br/>
<br/>
<br/>
<br/>
<span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
International Users<br/></span><br/>
Our digital operations are conducted, in whole or in part, in the United States.
Regardless of where you live, you consent to have your personal data transferred,
processed and stored in the United States, and allow Record Break'r
to use and collect your personal information in accordance with this Privacy Policy.<br/>
<br/>
<br/>
<span style={{ fontSize: '12pt', fontWeight: 'bold' }}><br/>
Terms of Use<br/></span><br/>
Your use of our products and services, and any disputes arising from them,
is subject to this Privacy Policy as well as our <a href="#/info/terms">Terms of Use</a>,
which explains other terms governing the use of our products and services.<br/></p>
<p><br/>
For questions or concerns, please contact Record Break'r Consumer Services/Privacy, P.O. Box 2435, Lake Oswego, OR 97035.<br/>
</p>
        </Container>
    )
}

export default Privacy
