import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { getResource } from '../util/resource'


function Swimmers() {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [swimmers, setSwimmers] = useState([])

    function search() {
        setLoading(true)
        getResource('/swimmers?name=' + name).then(res => {
            const swimmers = res.usms_swimmers
            //if (swimmers.length == 1)
            //    location(swimmers[0])
            swimmers.sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)))
            setSwimmers(swimmers)
            setLoading(false)
    	})
    }

    const autoFocus = useCallback(el => el ? el.focus() : null, [])

    return (
        <Container>
            <Form>
                <legend style={{ borderBottom: '1px solid #e5e5e5'}}>
                    <FontAwesomeIcon icon={faStar} className="gold me-2"/>
                    <span className="legend">Swimmers</span>
                </legend>
            </Form>
            <br/>
            <h6>First name <i>or</i> Last name</h6>
            <InputGroup className="col-md-3" style={{ padding: 0 }}>
                <FormControl type="text" value={name} ref={autoFocus}
                    onChange={e => setName(e.target.value)}
                    onKeyPress={ e => { if (e.code === 'Enter') search() } } />
                <Button className="gold-background" onClick={search}>Search</Button>
            </InputGroup>

            { loading && <ProgressBar animated now={100}/> }

            { swimmers.length > 0 &&
                <Card style={{ marginTop: '16px' }}>
                    <Card.Body>
                        Select a name to see their individual swim times
                        <ListGroup>
                        { swimmers.map(swimmer =>
                            <Link to={`/swimmer/${swimmer.usms_id}/times`} key={swimmer.usms_id}>
                                <ListGroup.Item>
                                    <h5>{ swimmer.name } <Badge variant="info">{ swimmer.usms_id }</Badge></h5>
                                    {/* <h6>{ swimmer.club } / { swimmer.group }</h6> */}
                                </ListGroup.Item>
                            </Link>
                        )}
                        </ListGroup>
                    </Card.Body>
                </Card>
            }
        </Container>
    )
}

export default Swimmers