import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLayerGroup, faList, faReply, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'

import { getResource, postResource } from '../util/resource'


function MeetSwimmers({ user }) {
    const { meet_id } = useParams()
    const [meet, setMeet] = useState()
    const [teams, setTeams] = useState([])
    const [club, setClub] = useState()
    const [group, setGroup] = useState()
    //const [swimmers, setSwimmers] = useState([])
    const [loading, setLoading] = useState()
    const navigate = useNavigate()

    function select_swimmer() {}
    function filter(swimmer) {
        if (!club)  return true
        if (club !== swimmer.club)  return false
        if (!group) return true
        return (group === swimmer.group)
    }

    function load_meet(roster=false) {
        setLoading(true)
        let url = '/meet/'+meet_id
        if (roster) url += '?roster=true'
        getResource(url)
            .then(meet => {
                meet.clubs = [...new Set(meet.swimmers.map(swimmer => swimmer.club))].sort()
                meet.groups = [...new Set(meet.swimmers.map(swimmer => swimmer.group))].filter(x=>x).sort()
                for (const s of meet.swimmers)
                    if (!s.events) s.events = {}
                setMeet(meet)
                setLoading(false)
            })
    }
    useEffect(load_meet, [meet_id])

    useEffect(() => { getResource('/teams/'+meet_id).then(setTeams) }, [meet_id])

    if (!meet)   return null

    function CreateTeam() {
        for (const team of teams)
            if (team.club === club && team.group === group)
            return (
                <Link to={`/team/${team.id}`}>
                    <Button>
                        <Badge variant="secondary">{ team.club }</Badge> { team.name }
                    </Button>
                </Link>
            )
        if (!user)
            return <div>Login to create a team</div>
        return <Button onClick={create_team}>Create Relay Team</Button>
    }

    function create_team() {
        postResource('/team', { meet_id, club, group })
            .then(team => {
                if (team.team_id)
                    navigate('/team/' + team.team_id)
            })
    }

    return (
        <Container>

        <p>{ meet.dates }</p>
        <h4>
            <Link to={`/meet/${ meet_id }`} className="me-2">
                <Button variant="info"><FontAwesomeIcon icon={faReply}/></Button>
            </Link>
            { meet.name } <Badge variant="secondary">{ meet.course }</Badge>
        </h4>

        <Form noValidate>
            <legend style={{ borderBottom: '1px solid #e5e5e5'}}>
                <span className="legend">
                    <FontAwesomeIcon icon={faList} className="gold me-2"/>Meet Swimmers
                </span>
                <DropdownButton as={ButtonGroup} title={ meet.swimmers.length + ' Swimmers' } className="ms-3">
                    <Dropdown.Item onClick={ () => load_meet(true) }>
                        <FontAwesomeIcon icon={faLayerGroup} className="gold me-2"/>Update roster
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/meet/${meet_id}/swimmer/add`}>
                        <FontAwesomeIcon icon={faUser} className="gold me-2"/>Add Swimmer
                    </Dropdown.Item>
                </DropdownButton>
            </legend>
        </Form>

        <Row>
            <Col>
                <p>
                    Set up relay teams by selecting swimmers from a USMS Club,
                    and optionally from a Workout Group
                </p>
                <Table>
                    <tbody>
                    <tr>
                        <th>Club</th>
                        <td>
                            <Form.Control as="select" value={club} className="mx-2"
                                        onChange={ e => setClub(e.target.value) }>
                                <option value=''>All</option>
                                { meet.clubs.map(club => <option key={club}>{ club }</option>) }
                            </Form.Control>
                        </td>
                        <td>
                            { club && !group && <CreateTeam/> }
                        </td>
                    </tr>
                    <tr>
                        <th>Group</th>
                        <td>
                            <Form.Control as="select" value={group} className="mx-2"
                                        onChange={ e => setGroup(e.target.value) }>
                                <option value=''>All</option>
                                { meet.groups.map(group => <option key={group}>{ group }</option>) }
                            </Form.Control>
                        </td>
                        <td>
                            { group && <CreateTeam/> }
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
            <Col>
                <b>Relay events</b>
                <div style={{ columns: '2 200px' }}>
                { Object.values(meet.events)
                        .filter(event => event.relay)
                        .sort((a,b) => parseInt(a.name) - parseInt(b.name))
                        .map((event, number) =>
                    <div key={number}>{ event.name }</div>
                    ) }
                </div>
            </Col>
        </Row>

        { loading && <ProgressBar animated now={100}/> }

{/*}
<div class="alert alert-dismissable alert-{{ alert.style }}" ng-repeat="alert in alerts">{ alert.body }</div>

<style>
.excluded td { background-color: #CCC !important; text-decoration: line-through }
.fa-check { color: #3276B1 }
.fa-times { color: #D2322D }
</style>
*/}

        { meet.swimmers &&
            <Table bordered striped className="table-list">
                <thead><tr>
                    <th>Name</th><th>Club</th><th>Group</th><th>S</th><th>Age</th>
                    { Object.keys(meet.events).filter(number => meet.events[number].relay)
                            .map(number => <th key={number}>{ number }</th>) }
                </tr></thead>
                <tbody>
                    { meet.swimmers.filter(filter).map((swimmer, i) =>
                        <tr onClick={() => select_swimmer(swimmer)} style={{ cursor: "pointer" }}
                            ng-class="{excluded: not_event(swimmer, event_id)}" key={i}>
                        <td>{ swimmer.name }</td>
                        <td>{ swimmer.club }</td>
                        <td>{ swimmer.group }</td>
                        <td>{ swimmer.sex }</td>
                        <td>{ swimmer.age }</td>
                        { Object.keys(meet.events).filter(number => meet.events[number].relay)
                                .map(number => <td key={number}>
                            { swimmer.events[number] === true ? <FontAwesomeIcon icon={ faCheck } className="gold me-2"/>
                            : swimmer.events[number] === false ? <FontAwesomeIcon icon={ faTimes } className="gold me-2"/>
                            : '' }
                            </td>) }
                        </tr>
                    )}
                </tbody>
            </Table>
        }

        </Container>
    )
}

export default MeetSwimmers
