import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Table from 'react-bootstrap/Table'

import { getResource, postResource } from '../util/resource'
import pin from './icons/pin.png'


function MeetsUpcoming() {
    const [meet, setMeet] = useState()
    const [meets, setMeets] = useState()
    //const [info, setInfo] = useState()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        getResource('/meet?upcoming=true')
            .then(meets => {
                const downloaded = {}
                for (const meet of meets.meets)
                    downloaded[meet.id] = meet
                const re_id = /.*c=(\d+)&smid=(\d+)/
                for (const meet of meets.upcoming) {
                    meet.id = meet.link.replace(re_id, '$1_$2')
                    if (downloaded[meet.id])
                        meet.downloaded = true
                }
                setMeets(meets.upcoming)
                setLoading(false)
            })
    }, [])

    function download_meet() {
        const url = new URL(meet.link, 'https://www.clubassistant.com')
        setLoading(true)
        postResource('/meet', {c: url.searchParams.get('c'), smid: url.searchParams.get('smid')})
            .then(meet => {
                setLoading(false)
                if (meet?.meet_id)
                    navigate('/meet/' + meet.meet_id)
                setMeet(undefined)
            }
        )
    }

    return (
        <Container>
            <h3>USMS Pool Meets on ClubAssistant</h3>

            { loading && <ProgressBar animated now={100}/> }

            {/* info && <p className={`label label-${ info.style }`}>{ info.body }</p> */}

            { meets &&
            <Table className="table-list">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Address</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    { meets.map((meet, i) => (
                    <tr key={i} style={{ cursor: "pointer" }}>
                        <td><a href={"https://www.clubassistant.com" + meet.link}
                                target="_blank" rel="noopener noreferrer">{ meet.name }</a>
                            <br/>{ meet.register }
                        </td>
                        <td>{ meet.date }</td>
                        <td style={{ whiteSpace: "pre" }}>
                            <a href={ "https://www.google.com/maps/search/?api=1&query="
                                      + encodeURIComponent(meet.address) }
                                      target="_blank" rel="noopener noreferrer">
                                <img src={ pin } alt="pin"/> { meet.address }
                            </a>
                        </td>
                        <td>{ meet.downloaded
                            ? <Link to={'/meet/'+meet.id}><Button>View Meet</Button></Link>
                            : <Button onClick={ () => setMeet(meet) }>Download</Button>
                            }
                        </td>
                    </tr>
                    )) }
                </tbody>
            </Table>
            }

            { meet &&
            <Modal show={ meet ? true : false }>
                <Modal.Body>Download this meet?<br/>{ meet.name }</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setMeet(undefined) }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={download_meet}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            }

        </Container>
    )
}

export default MeetsUpcoming