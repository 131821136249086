import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

import { getResource, putResource } from '../util/resource'
import usms_ico from './icons/usms.ico'

const LMSCS = [
    {"abbr":"AD","name":"Adirondack"},
    {"abbr":"AK","name":"Alaska"},
    {"abbr":"AM","name":"Allegheny Mountain"},
    {"abbr":"AR","name":"Arkansas"},
    {"abbr":"AZ","name":"Arizona"},
    {"abbr":"CO","name":"Colorado"},
    {"abbr":"CT","name":"Connecticut"},
    {"abbr":"DV","name":"Delaware Valley"},
    {"abbr":"FG","name":"Florida Gold Coast"},
    {"abbr":"FL","name":"Florida"},
    {"abbr":"GA","name":"Georgia"},
    {"abbr":"GU","name":"Gulf"},
    {"abbr":"HI","name":"Hawaii"},
    {"abbr":"IA","name":"Iowa"},
    {"abbr":"IL","name":"Illinois"},
    {"abbr":"IN","name":"Indiana"},
    {"abbr":"IW","name":"Inland Northwest"},
    {"abbr":"KY","name":"Kentucky"},
    {"abbr":"LE","name":"Lake Erie"},
    {"abbr":"MD","name":"Maryland"},
    {"abbr":"MI","name":"Michigan"},
    {"abbr":"MN","name":"Minnesota"},
    {"abbr":"MR","name":"Metropolitan"},
    {"abbr":"MT","name":"Montana"},
    {"abbr":"MV","name":"Missouri Valley"},
    {"abbr":"NB","name":"Nebraska"},
    {"abbr":"NC","name":"North Carolina"},
    {"abbr":"ND","name":"North Dakota"},
    {"abbr":"NE","name":"New England"},
    {"abbr":"NI","name":"Niagara"},
    {"abbr":"NJ","name":"New Jersey"},
    {"abbr":"NM","name":"New Mexico"},
    {"abbr":"NT","name":"North Texas"},
    {"abbr":"OH","name":"Ohio"},
    {"abbr":"OK","name":"Oklahoma"},
    {"abbr":"OR","name":"Oregon"},
    {"abbr":"OZ","name":"Ozark"},
    {"abbr":"PC","name":"Pacific"},
    {"abbr":"PN","name":"Pacific Northwest"},
    {"abbr":"PV","name":"Potomac Valley"},
    {"abbr":"SC","name":"South Carolina"},
    {"abbr":"SD","name":"South Dakota"},
    {"abbr":"SE","name":"Southeastern"},
    {"abbr":"SI","name":"San Diego - Imperial"},
    {"abbr":"SO","name":"Southern"},
    {"abbr":"SP","name":"Southern Pacific"},
    {"abbr":"SR","name":"Snake River"},
    {"abbr":"ST","name":"South Texas"},
    {"abbr":"UT","name":"Utah"},
    {"abbr":"VA","name":"Virginia"},
    {"abbr":"WI","name":"Wisconsin"},
    {"abbr":"WT","name":"West Texas"}
]

function Map({ select_lmsc }) {
    return (
    <map name="usmsmap" id="usmsmap">
        <area shape="poly" coords="623,133,621,127,628,126,617,82,599,87,591,103,602,128" onClick={ () => select_lmsc("AD") } alt="Adirondack" title="Adirondack"/>
        <area shape="poly" coords="165,398,186,388,218,392,234,446,280,464,274,470,222,448,195,465,150,492" onClick={ () => select_lmsc("AK") } alt="Alaska" title="Alaska"/>
        <area shape="poly" coords="574,178,579,165,573,141,555,143,547,148,547,164,547,177,550,182" onClick={ () => select_lmsc("AM") } alt="Allegheny Mountain" title="Allegheny Mountain"/>
        <area shape="poly" coords="186,338,160,336,113,309,130,241,191,250" onClick={ () => select_lmsc("AZ") } alt="Arizona" title="Arizona"/>
        <area shape="poly" coords="445,265,435,263,435,257,384,262,392,309,429,310" onClick={ () => select_lmsc("AR") } alt="Arkansas" title="Arkansas"/>
        <area shape="poly" coords="285,252,284,188,261,185,261,124,184,117,178,180,200,183,195,245" onClick={ () => select_lmsc("CO") } alt="Colorado" title="Colorado"/>
        <area shape="poly" coords="633,141,630,127,648,122,650,132" onClick={ () => select_lmsc("CT") } alt="Connecticut" title="Connecticut"/>
        <area shape="poly" coords="618,189,612,173,574,177,578,156,576,143,605,133,615,150,626,170" onClick={ () => select_lmsc("DV") } alt="Delaware Valley" title="Delaware Valley"/>
        <area shape="poly" coords="589,387,587,392,592,406,580,406,559,377,547,353,517,353,525,337,558,334,569,332,595,382" onClick={ () => select_lmsc("FL") } alt="Florida" title="Florida"/>
        <area shape="poly" coords="591,403,586,393,588,387,598,387" onClick={ () => select_lmsc("FG") } alt="Florida Gold Coast" title="Florida Gold Coast"/>
        <area shape="poly" coords="522,336,519,312,505,273,534,267,545,284,572,306,567,330" onClick={ () => select_lmsc("GA") } alt="Georgia" title="Georgia"/>
        <area shape="poly" coords="366,386,359,370,368,371,365,360,358,351,396,335,395,365" onClick={ () => select_lmsc("GU") } alt="Gulf" title="Gulf"/>
        <area shape="poly" coords="32,360,90,380,112,402,96,416,22,373" onClick={ () => select_lmsc("HI") } alt="Hawaii" title="Hawaii"/>
        <area shape="poly" coords="449,243,441,228,443,212,434,203,419,200,424,181,431,161,456,154,467,213" onClick={ () => select_lmsc("IL") } alt="Illinois" title="Illinois"/>
        <area shape="poly" coords="463,235,502,209,493,160,467,169" onClick={ () => select_lmsc("IN") } alt="Indiana" title="Indiana"/>
        <area shape="poly" coords="134,44,133,69,145,85,138,104,119,105,117,90,74,89,72,76,66,77,84,35" onClick={ () => select_lmsc("IW") } alt="Inland Northwest" title="Inland Northwest"/>
        <area shape="poly" coords="414,140,419,156,430,167,421,174,416,192,362,191,353,150,355,147" onClick={ () => select_lmsc("IA") } alt="Iowa" title="Iowa"/>
        <area shape="poly" coords="538,221,523,241,447,248,482,224,506,205,530,207" onClick={ () => select_lmsc("KY") } alt="Kentucky" title="Kentucky"/>
        <area shape="poly" coords="540,176,542,168,547,163,545,149,535,159,521,162,516,168" onClick={ () => select_lmsc("LE") } alt="Lake Erie" title="Lake Erie"/>
        <area shape="poly" coords="620,206,623,188,609,173,567,180,568,186,580,177,599,179,606,195,599,196,599,193" onClick={ () => select_lmsc("MD") } alt="Maryland" title="Maryland"/>
        <area shape="poly" coords="647,138,631,147,630,136,627,130,612,135,626,147" onClick={ () => select_lmsc("MR") } alt="Metropolitan" title="Metropolitan"/>
        <area shape="poly" coords="463,162,510,157,517,129,492,97,477,84,439,84,427,93,451,105" onClick={ () => select_lmsc("MI") } alt="Michigan" title="Michigan"/>
        <area shape="poly" coords="413,140,403,126,407,116,397,115,393,109,398,96,405,78,422,66,392,62,354,57,340,56,353,144" onClick={ () => select_lmsc("MN") } alt="Minnesota" title="Minnesota"/>
        <area shape="poly" coords="383,257,405,253,397,242,417,214,402,193,367,196,370,203,286,205,290,252" onClick={ () => select_lmsc("MV") } alt="Missouri Valley" title="Missouri Valley"/>
        <area shape="poly" coords="262,120,262,97,249,88,250,71,263,77,263,56,135,45,139,74,144,103,165,121" onClick={ () => select_lmsc("MT") } alt="Montana" title="Montana"/>
        <area shape="poly" coords="367,200,349,161,260,156,262,187,284,185,287,200" onClick={ () => select_lmsc("NB") } alt="Nebraska" title="Nebraska"/>
        <area shape="poly" coords="653,129,664,122,654,115,658,94,665,81,686,56,670,34,651,30,644,68,618,82,632,123,648,119" onClick={ () => select_lmsc("NE") } alt="New England" title="New England"/>
        <area shape="poly" coords="629,164,616,154,618,142,627,149" onClick={ () => select_lmsc("NJ") } alt="New Jersey" title="New Jersey"/>
        <area shape="poly" coords="267,330,270,253,195,249,187,336" onClick={ () => select_lmsc("NM") } alt="New Mexico" title="New Mexico"/>
        <area shape="poly" coords="603,132,594,110,583,120,562,124,564,134,555,143" onClick={ () => select_lmsc("NI") } alt="Niagara" title="Niagara"/>
        <area shape="poly" coords="519,266,561,259,577,260,596,268,614,253,619,237,626,230,622,220,545,237" onClick={ () => select_lmsc("NC") } alt="North Carolina" title="North Carolina"/>
        <area shape="poly" coords="348,106,341,56,266,54,267,83,251,77,257,90,265,104" onClick={ () => select_lmsc("ND") } alt="North Dakota" title="North Dakota"/>
        <area shape="poly" coords="353,346,392,331,391,310,376,302,332,300,312,294,311,261,271,263,272,330,323,332" onClick={ () => select_lmsc("NT") } alt="North Texas" title="North Texas"/>
        <area shape="poly" coords="533,209,503,200,497,160,518,161,513,169,521,175,535,176,545,171" onClick={ () => select_lmsc("OH") } alt="Ohio" title="Ohio"/>
        <area shape="poly" coords="385,303,381,249,272,253,272,261,310,260,315,292" onClick={ () => select_lmsc("OK") } alt="Oklahoma" title="Oklahoma"/>
        <area shape="poly" coords="42,75,65,76,74,86,104,88,119,97,107,113,97,114,90,149,21,138" onClick={ () => select_lmsc("OR") } alt="Oregon" title="Oregon"/>
        <area shape="poly" coords="446,260,448,231,438,229,438,212,431,208,421,203,414,190,405,191,407,200,418,209,414,219,404,231,402,245,407,253" onClick={ () => select_lmsc("OZ") } alt="Ozark" title="Ozark"/>
        <area shape="poly" coords="19,140,106,155,100,169,106,175,102,199,82,200,77,214,101,255,34,244" onClick={ () => select_lmsc("PC") } alt="Pacific" title="Pacific"/>
        <area shape="poly" coords="64,30,83,35,65,71,41,66,40,37" onClick={ () => select_lmsc("PN") } alt="Pacific Northwest" title="Pacific Northwest"/>
        <area shape="poly" coords="600,191,594,189,594,182,601,186" onClick={ () => select_lmsc("PV") } alt="Potomac Valley" title="Potomac Valley"/>
        <area shape="poly" coords="113,307,111,299,78,289,81,304" onClick={ () => select_lmsc("SI") } alt="San Diego - Imperial" title="San Diego - Imperial"/>
        <area shape="poly" coords="152,106,157,122,179,123,174,162,139,160,133,212,107,199,109,174,105,160,92,151,100,116,119,103" onClick={ () => select_lmsc("SR") } alt="Snake River" title="Snake River"/>
        <area shape="poly" coords="572,304,533,270,549,258,565,261,579,261,597,270" onClick={ () => select_lmsc("SC") } alt="South Carolina" title="South Carolina"/>
        <area shape="poly" coords="353,162,347,110,266,106,265,154" onClick={ () => select_lmsc("SD") } alt="South Dakota" title="South Dakota"/>
        <area shape="poly" coords="354,431,328,427,288,372,302,361,300,337,328,333,360,354,361,389" onClick={ () => select_lmsc("ST") } alt="South Texas" title="South Texas"/>
        <area shape="poly" coords="502,272,517,349,472,344,471,276,438,276,446,255,539,239,536,246" onClick={ () => select_lmsc("SE") } alt="Southeastern" title="Southeastern"/>
        <area shape="poly" coords="401,366,390,314,434,313,439,278,467,275,472,347,466,373" onClick={ () => select_lmsc("SO") } alt="Southern" title="Southern"/>
        <area shape="poly" coords="82,203,114,205,121,212,131,214,128,254,117,255,119,286,114,295,77,291,41,265,38,251,102,257" onClick={ () => select_lmsc("SP") } alt="Southern Pacific" title="Southern Pacific"/>
        <area shape="poly" coords="146,163,176,167,175,177,196,188,193,247,131,241,140,163" onClick={ () => select_lmsc("UT") } alt="Utah" title="Utah"/>
        <area shape="poly" coords="526,240,539,222,539,199,552,185,566,182,566,188,584,181,595,196,611,206,620,221" onClick={ () => select_lmsc("VA") } alt="Virginia" title="Virginia"/>
        <area shape="poly" coords="265,381,278,368,287,367,287,363,297,361,301,333,224,334" onClick={ () => select_lmsc("WT") } alt="West Texas" title="West Texas"/>
        <area shape="poly" coords="455,152,450,110,415,90,399,101,401,113,420,143,427,156" onClick={ () => select_lmsc("WI") } alt="Wisconsin" title="Wisconsin"/>
    </map>
    )
}
const this_year = new Date().getFullYear()
const years = []
for (let year = 2000; year <= this_year; ++year)
    years.push(year)

function Directory(props) {
    return (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
            overlay={<Popover><Popover.Body>USMS Directory</Popover.Body></Popover>}>
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                <Button variant="light outline-primary" style={{ padding: 2 }}>
                    <img src={usms_ico} alt="USMS"/>
                </Button>
            </a>
        </OverlayTrigger>
    )
}

function Members({ members, group }) {
    if (!members)
        return null
    const filtered = group ? members.filter(member => member.group === group.abbr) : members
    const has_groups = filtered.some(member => member.group)

    return (
        <Table className="table table-list">
            <thead><tr className="blue-gradient">
                <th>Name</th>
                <th>Times</th>
                { has_groups && <th>Group</th> }
                <th>USMS ID</th>
            </tr></thead>
            <tbody>
                { filtered.map(swimmer =>
                    <tr key={swimmer.usms_id} ng-click="select_swimmer(swimmer)"
                        ng-class-odd="'table-stripe'" style={{cursor: 'pointer'}}>
                    <td>{ swimmer.name }</td>
                    <td><Link to={`/swimmer/${swimmer.usms_id}/times`}>Times</Link></td>
                    {/*
                    <td>{ swimmer.sex }
                        <Button variant={swimmer.sex === 'M' ? "primary" : "outline-primary"}
                            onClick={() => set_swimmer(swimmer, 'M')}>M</Button>
                        <Button variant={swimmer.sex === 'W' ? "danger" : "outline-danger"}
                            onClick={() => set_swimmer(swimmer, 'W')}>W</Button>
                    </td>
                    */}
                    { has_groups && <td>{ swimmer.group }</td> }
                    <td><a href={'https://www.usms.org/comp/meets/indresults.php?SwimmerID='+swimmer.usms_id}
                            target='_blank' rel="noopener noreferrer">
                        { swimmer.usms_id }
                    </a></td>
                    </tr>
                ) }
            </tbody>
        </Table>
    )
}

export default function Clubs() {
    const [lmsc, setLmsc] = useState('')
    const [regYear, setRegYear] = useState(this_year)
    const [club, setClub] = useState()
    const [group, setGroup] = useState()
    const [members, setMembers] = useState()
    const [clubs, setClubs] = useState(null)
    const [groups, setGroups] = useState(null)
    const [loading, setLoading] = useState(false)
    const [downloading, setDownloading] = useState(true)

    function select_lmsc(new_lmsc) {
        setLmsc(new_lmsc)
        setClub(undefined)
        setGroup(undefined)
        setMembers(undefined)
        if (!new_lmsc) {
            setClubs(null)
            setGroups(null)
            return
        }
    
        setLoading(true)
        getResource('/usms/lmsc?abbr='+new_lmsc)
            .then(res => {
                if (!res.clubs) return setLoading(false)
                const clubs = []
                const groups = []
                Object.entries(res.clubs).map(([abbr, club]) => {
                    clubs.push({abbr, name: `(${abbr}) ${club.name}`})
                    if (club.groups)
                    Object.entries(club.groups).map(([group_abbr, group]) =>
                        groups.push({club: abbr, group_id: group.group_id, abbr: group_abbr,
                                     name: `(${group_abbr}) ${group.name}`})
                    )
                    return null
                })
                clubs.sort((a, b) => a.name.localeCompare(b.name))
                groups.sort((a, b) => a.name.localeCompare(b.name))
                setClubs(clubs)
                setGroups(groups)
                setLoading(false)
            })
    }

    function select_year(new_year) {
        setRegYear(new_year)
        if (club)
            get_members(club, new_year)
    }

    function select_club(new_club) {
        setClub(new_club)
        setGroup(undefined)
        get_members(new_club, regYear)
    }

    function select_group(new_group) {
        for (const g of groups)
            if (g.abbr === new_group) {
                if (!club)
                    get_members(g.club, regYear)
                setClub(g.club)
                setGroup(g)
                return
            }
        setGroup(undefined)
    }

    function get_members(club, year) {
        setMembers(undefined)
        if (!club)  return
        setLoading(true)
        getResource('/usms/club/'+club+'/members?year='+year)
            .then(res => {
                if (res.club) {
                    const members = JSON.parse(res.club.members)
                    members.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                    setMembers(members)
                }
                setLoading(false)
            })
    }

    function download_club() {
        setDownloading(true)
        putResource(`/usms/club/${club}/members`).then(res => {
            setDownloading(false)
            get_members(club, regYear)
        })
    }
/*
    function set_swimmer(swimmer, sex) {
        postResource(`/swimmer/${swimmer.usms_id}`, {sex})
    }
*/
    function Groups() {
        if (!groups)
            return null
        const club_groups = club ? groups.filter(g => g.club === club) : groups
        if (!club_groups.length)
            return null
        return (
            <Form.Group as={Row} controlId="id_group">
                <Form.Label column xs={3} sm={4} md={3} className="text-right"><b>Workout Group</b></Form.Label>
                <Col xs={7} sm={6} md={5} lg={4}>
                    <Form.Select onChange={ e => select_group(e.target.value) } value={group ? group.abbr : ''}>
                        <option value=''>All Workout Groups</option>
                        { club_groups.map(g => <option key={g.abbr} value={g.abbr}>{ g.name }</option>) }
                    </Form.Select>
                </Col>
                { group && <Col xs={2}>
                    <Directory href={"https://www.usms.org/reg/members/wogroup.php?WOGroupID="+group.group_id+"&RegYear="+regYear}/>
                </Col>}
            </Form.Group>
        )
    }

    return (
    <Container>
    <Form role="form" noValidate style={{ marginBottom: "20px" }}>
        <legend>
            <FontAwesomeIcon icon={faFlag} className="gold me-2"/>
            <span className="legend">USMS Clubs / Workout Groups</span>
        </legend>

        <Form.Group as={Row} controlId="id_lmsc">
            <Form.Label column xs={5} sm={4} md={3} className="text-right"><b>Region (LMSC)</b></Form.Label>
            <Col xs={6} sm={5} md={4} lg={3}>
                <Form.Select onChange={ e => select_lmsc(e.target.value) } value={lmsc}>
                    <option value=''>Map</option>
                    { LMSCS.map(lmsc => <option key={lmsc.abbr} value={lmsc.abbr}>({ lmsc.abbr }) { lmsc.name }</option>) }
                </Form.Select>
            </Col>

            { !lmsc &&
            <Col xs={12}>
                <img src="/lmscmap-700.png" useMap="#usmsmap" alt="LMSC map"/>
                <Map select_lmsc={ select_lmsc } />
            </Col>
            }
        </Form.Group>

        { lmsc &&
        <Form.Group as={Row} controlId="id_year">
            <Form.Label column xs={5} sm={4} md={3} className="text-right"><b>Registration Year</b></Form.Label>
            <Col xs={3} sm={2}>
                <Form.Select onChange={ e => select_year(e.target.value) } value={regYear}>
                    { years.map(year => <option key={year}>{ year }</option>) }
                </Form.Select>
            </Col>
        </Form.Group>
        }

        {/*!loading && !clubs &&
            <div>
                <Button disabled={loading} onClick={() => {
                    setLoading(true)
                    getResource('/task/clubs').then(() => setLoading(false))
                }}>
                    Update Clubs
                </Button>
            </div>
        */}

        { clubs && (
        <Form.Group as={Row} controlId="id_club">
            <Form.Label column xs={3} sm={4} md={3} className="text-right"><b>Club</b></Form.Label>
            <Col xs={7} sm={6} md={5} lg={4}>
                <Form.Select onChange={ e => select_club(e.target.value) } value={club}>
                    <option value=''>Choose a Club</option>
                    { clubs.map(club => <option key={club.abbr} value={club.abbr}>{ club.name }</option>) }
                </Form.Select>
            </Col>
            { club && <Col xs={2}>
                <Directory href={"https://www.usms.org/reg/members/club.php?ClubAbbr="+club+"&RegYear="+regYear}/>
            </Col>}
        </Form.Group>
        ) }

        <Groups />

        { club &&
            <Row>
                <Col xs={{ span: 9, offset: 3 }} sm={{ span: 6, offset: 4 }} md={{ span: 6, offset: 3 }}>
                    <Button onClick={download_club} variant="primary">
                        { downloading && <i className="fa fa-spinner fa-spin"></i> }
                        Update members from USMS
                    </Button>
                </Col>
            </Row>
        }

        { loading && <ProgressBar animated now={100}/> }

        <Members members={members} group={group} />

        { (club && groups && members && !groups.length && !members.length && !loading) &&
            <div className="alert alert-info">No members are currently registered in this club.</div>
        }
    </Form>
    </Container>
    )
}
