import React, { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'

import { formatted_time } from '../util/format'
import { getResource } from '../util/resource'


const ALL = 'All'
const COURSES = [
    {course: 'LCM', label: 'Long Course Meters (LCM)'},
    {course: 'SCM', label: 'Short Course Meters (SCM)'},
    {course: 'SCY', label: 'Short Course Yards (SCY)'}
]
const EVENTS = [
    {'sg': 'IM', 'label': 'Mens Individual'},
    {'sg': 'IW', 'label': 'Womens Individual'},
    {'sg': 'RM', 'label': 'Mens Relay'},
    {'sg': 'RW', 'label': 'Womens Relay'},
    {'sg': 'RX', 'label': 'Mixed Relay'}
]

function Records() {
    const [session, setSession] = useState({})
    const [loading, setLoading] = useState(false)
    const [course, setCourse] = useState(COURSES[0].course)
    const [records, setRecords] = useState()
    const [sg, setSg] = useState('IM')

    useEffect(() => {getResource('/session').then(setSession)}, [])

    function getRecords() {
        setLoading(true)
        getResource(`/records?course=${course}&gender=${sg.charAt(1)}&style=${sg.charAt(0)}`)
            .then(regions => {
                const as_of = []
                const records = []
                for (const region of regions) {
                    as_of.push(region.as_of)
                    records.push(...JSON.parse(region.holders))
                }
                setRecords(records)
                setLoading(false)
            })
    }
    useEffect(getRecords, [course, sg])

    if (!records) return null

    return (
        <Container>
            <Form>
                <legend>
                    <FontAwesomeIcon icon={faTrophy} className="gold me-2"/>
                    <span className="legend">Records</span>
                </legend>
                <Row>
                    <Col xs={7} sm={4}>
                        <Form.Select value={course} id='course'
                            onChange={ e => setCourse(e.target.value) }>
                            { COURSES.map(course => <option key={course.course} value={course.course}>{ course.label }</option>) }
                        </Form.Select>
                    </Col>
                    <Col xs={5} sm={3}>
                        <Form.Select value={sg} id='sg'
                            onChange={ e => setSg(e.target.value) }>
                            { EVENTS.map(event => <option key={event.sg} value={event.sg}>{ event.label }</option>) }
                        </Form.Select>
                    </Col>
                    {/* user && context.region &&
                        <Col xs={4} sm={2}>
                            <Button variant="primary" onClick={download} className="float-right">
                                <FontAwesomeIcon icon={faCloudDownloadAlt} className="gold me-2"/>
                            </Button>
                        </Col>
                    */}
                </Row>
            </Form>
            { loading && <ProgressBar animated now={100} /> }
            <RecordFilters course={course} style={sg.charAt(0)} records={records} session={session} />
            {/* <div>{JSON.stringify(AsOf[0])}</div> */}
        </Container>
    )
}


const REGIONS = [ALL, 'FINA', 'USMS', 'ZONE', 'OREG', 'MAC']

function RecordFilters({ course, style, records, session }) {
    const [age_group, setAgeGroup] = useState(ALL)
    const [age_groups, setAgeGroups] = useState([])
    const [stroke, setStroke] = useState(ALL)
    const [strokes, setStrokes] = useState([])
    const [region, setRegion] = useState('USMS')
    const [year, setYear] = useState(ALL)
    const [years, setYears] = useState([ALL])
    const [filtered_records, setFilteredRecords] = useState(records)
    const [updating, setUpdating] = useState(false)

    function pulldowns() {
        let age_groups, strokes
  	    if (style === 'I') {	// Individual
    		age_groups = ['18-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64',
                          '65-69', '70-74', '75-79', '80-84', '85-89', '90-94', '95-99', '100-104']
    		if (course === 'LCM')
    		    strokes = ['50 Free', '100 Free', '200 Free', '400 Free', '800 Free', '1500 Free',
                            '50 Back', '100 Back', '200 Back', '50 Breast', '100 Breast', '200 Breast',
                            '50 Fly', '100 Fly', '200 Fly', '200 IM', '400 IM']
    		else if (course === 'SCM')
    		    strokes = ['50 Free', '100 Free', '200 Free', '400 Free', '800 Free', '1500 Free',
                            '50 Back', '100 Back', '200 Back', '50 Breast', '100 Breast', '200 Breast',
                            '50 Fly', '100 Fly', '200 Fly', '100 IM', '200 IM', '400 IM']
    		else
    		    strokes = ['50 Free', '100 Free', '200 Free', '500 Free', '1000 Free', '1650 Free',
                            '50 Back', '100 Back', '200 Back', '50 Breast', '100 Breast', '200 Breast',
                            '50 Fly', '100 Fly', '200 Fly', '100 IM', '200 IM', '400 IM']
        } else {	// Relays
            age_groups = course === 'SCY'
                ? ['18+', '25+', '35+', '45+', '55+', '65+', '75+', '85+']
  	            : ['72-99', '100-119', '120-159', '160-199', '200-239', '240-279', '280-319', '320-359', '360-399']
  	        strokes = ['200 Free', '400 Free', '800 Free', '200 Medley', '400 Medley']
        }
        if (region === 'FINA') age_groups.shift() // no 18-24 or 72-99 for FINA
        age_groups.unshift(ALL)
        strokes.unshift(ALL)
        setAgeGroups(age_groups)
        setStrokes(strokes)
        if (!age_groups.includes(age_group)) setAgeGroup(ALL)
        if (!strokes.includes(stroke)) setStroke(ALL)
    }
    useEffect(pulldowns, [region, course, style, age_group, stroke])

    useEffect(() => {
        const years = new Set()
        for (const holder of records)
            if (region === holder[0])
                years.add(holder[5].toString().slice(0,4))
        setYears([ALL, ...Array.from(years).sort().reverse()])
    }, [records, region])

    useEffect(() => { setFilteredRecords(records.filter(holder => {
        if (region !== ALL && region !== holder[0]) return false
        if (age_group !== ALL && age_group !== holder[2]) return false
        if (stroke !== ALL && stroke !== holder[4]+' '+holder[3]) return false
        if (year !== ALL && year !== holder[5]?.toString().slice(0,4)) return false
        return true
    })) }, [records, region, age_group, stroke, year])

    function update(region, year) {
        setUpdating(true)
        let region_update = region.toLowerCase()
        if (region_update === 'mac') region_update = 'oreg_mac'
        let url = '/task/records/'+region_update
        if (year !== ALL) url += '?year='+year
        getResource(url).then((res) => {
            alert(JSON.stringify(res))
            setUpdating(false)
        })
    }

    session.admin=true
    return (
        <div>
        <Row className='mt-3 mb-5'>
            <Col xs={4} sm={3} md={2}>
                <b>Region</b>
                <Form.Select value={region} id='region'
                    onChange={ e => setRegion(e.target.value) }>
                    { REGIONS.map(region => <option key={region}>{region}</option>) }
                </Form.Select>
            </Col>
            <Col xs={4} sm={3} md={2}>
                <b>Age</b>
                <Form.Select value={age_group} id='age_group'
                    onChange={ e => setAgeGroup(e.target.value) }>
                    { age_groups.map(age => <option key={age}>{age}</option>) }
                </Form.Select>
            </Col>
            <Col xs={4} md={3} lg={2}>
                <b>Stroke</b>
                <Form.Select value={stroke} id='stroke'
                    onChange={ e => setStroke(e.target.value) }>
                    { strokes.map(stroke => <option key={stroke}>{stroke}</option>) }
                </Form.Select>
            </Col>
            <Col xs={4} md={3} lg={2}>
                <b>Year</b>
                <Form.Select value={year} id='year'
                    onChange={ e => setYear(e.target.value) }>
                    { years.map(year => <option key={year}>{year}</option>) }
                </Form.Select>
            </Col>
            { session.admin &&
                <Col xs={4} md={3}><br/>
                    { updating ? <Button disabled>Updating {region}</Button> :
                    <Button onClick={() => { update(region, year) }}>
                        Update {region} {year === ALL ? '' : year}
                    </Button> }
                </Col>
            }
        </Row>
        <RecordTable records={filtered_records}/>
        </div>
    )
}

function RecordTable({ records }) {
    return (
        <Table>
            <thead>
                <tr className='blue-gradient'>
                    <th>Region</th>
                    <th className='text-end'>Age group</th>
                    <th className='text-end'>Stroke</th>
                    <th className='text-end'>Time</th>
                    <th></th>
                    <th>Name</th>
                    <th>Year</th>
                </tr>
            </thead>
            <tbody style={{ backgroundColor: 'white' }}>
                { records.map(h =>
                    <tr key={h}>
                        <td>{ h[0] }</td>
                        <td className='text-end'>{ h[2] }</td>
                        <td className='text-end'>{ h[4] } { h[3] }</td>
                        <td className='text-end'>{ formatted_time(h[6]) }</td>
                        <td></td>
                        <td>{ h.slice(8, 12).join(', ') }</td>
                        <td>{ h[5]?.toString().slice(0,4) }</td>
                    </tr>
                ) }
            </tbody>
        </Table>
    )
}

export default Records