import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlagCheckered, faReply, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

import { formatted_time, region_variant, team_swimmers, relay_team_record, relay_age, relay_swimmer } from '../util/format'
import { getResource } from '../util/resource'

function Team() {
    const { team_id } = useParams()
    const [team, setTeam] = useState()
    const [usms, setUsms] = useState('')

    useEffect(() => {
        getResource(`/team/${team_id}`).then(team => {
            team_swimmers(team)
            team.event_dates = {}   // group the relay events by date
            for (var [number, event] of Object.entries(team.events) ) {
                if (!event.relay)   continue
                const dates = team.event_dates[event.date] || {}
                dates[number] = event
                team.event_dates[event.date] = dates
            }
            setTeam(team)
        })
    }, [team_id])

    if (!team)  return <ProgressBar animated now={100}/>

    function Swimmers({ relay, event }) {
        const distance = (event.distance / 4) + ' '
        const strokes = (event.relay === 'M')
                        ? ['Back', 'Breast', 'Fly', 'Free']
                        : ['Free', 'Free', 'Free', 'Free']
        return relay.swimmers.map((usms_id, s) => {
            const classes = []
            if (usms_id === usms)
                classes.push('highlight')
            /*
            if (not_event(swimmers[swimmer], number))
                classes.push('excluded')
            */
            return (
                <div key={s} className={ classes.join(' ') }>
                    { relay_swimmer(usms_id, distance + strokes[s])}
                </div>
            )
        } )
    }

    function Relays({ relays, event }) {
        if (!relays)    return null
        if (usms)   relays = relays.filter(relay => relay.swimmers.includes(usms))
        return relays.map((relay, i) =>
            <Alert variant={region_variant(relay.region)} key={i}>
                { relay.age_group }
                { relay_age(relay.swimmers, team.course) }
                { formatted_time(relay.time) }
                { relay_team_record(relay) }
                <Swimmers event={event} relay={relay} />
            </Alert>
        )
    }

    return (
        <Container>
            <p>{ team.meet_dates }</p>
            <h4>
                <Link to={ `/meet/${team.meet_id}` } className="me-2">
                    <Button variant="info" size="sm"><FontAwesomeIcon icon={faReply}/></Button>
                </Link>
                { team.meet_name } <Badge variant="secondary">{ team.course }</Badge>
            </h4>

            <h4>
                <FontAwesomeIcon icon={faFlagCheckered} className="gold me-2"/>
                { team.name }
                <Link to={ `/team/${team.id}/swimmers` } className="ms-2">
                    <Button>
                        <FontAwesomeIcon icon={faStar} className="gold me-2"/>
                        { team.swimmers.length } Swimmers
                    </Button>
                </Link>
            </h4>

            <Card>
                <Card.Header>
                    { team.relays ?
                        <Form>
                            <fieldset>
                                <FontAwesomeIcon icon={faUser} className="gold me-2"/>
                                <span className="hidden-xs" style={{display: 'inline!important'}}>Select a swimmer:</span>
                                <Form.Control as="select" value={usms} onChange={(e) => setUsms(e.target.value)}>
                                    <option key='' value="">All swimmers</option>
                                    { team.swimmers.map(swimmer => swimmer.usms_id
                                        ? <option key={swimmer.usms_id} value={swimmer.usms_id}>{swimmer.name}</option>
                                        : null)
                                    }
                                </Form.Control>
                            </fieldset>
                        </Form>
                        : <div>Select an Event and generate relay teams</div>
                    }
                </Card.Header>
                <Card.Body>
                    Relays are color-coded by region records
                    <div className="alert">
                        <span className="alert alert-info">FINA</span>
                        <span className="alert alert-danger">USMS</span>
                        <span className="alert alert-warning">Zone</span>
                        <span className="alert alert-success">Oregon</span>
                    </div>

                    { Object.entries(team.event_dates).map(([date, events]) =>
                        <Card key={date}>
                            <Card.Header>{ new Date(date+'T00:00:00').toDateString() }</Card.Header>
                            <Card.Body>
                                { Object.entries(events).map(([number, event]) =>
                                    <div key={number}>
                                        { team.is_Admin ?
                                        <Link to={`/team/${team.id}/event/${number}`}>
                                            <Button>{ event.name }</Button>
                                        </Link> : <Button>{ event.name }</Button> }
                                        <Relays relays={team.relays[number]} event={event} />
                                    </div>
                                ) }
                            </Card.Body>
                        </Card>
                    ) }
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Team