import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Container from 'react-bootstrap/Container'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFlagCheckered, faReply, faSwimmer, faSync, faTimes } from '@fortawesome/free-solid-svg-icons'

import { formatted_time } from '../util/format'
import { getResource, postResource, putResource } from '../util/resource'

const STROKES = ['Fly', 'Back', 'Breast', 'Free']

// "swimmer" is not in event "number"
function not_in_event(swimmer, number) {
    return swimmer && swimmer.not_events ? (swimmer.not_events.indexOf(number) !== -1) : false
}


function TeamSwimmers() {
    const navigate = useNavigate()
    const { team_id } = useParams()
    const [team, setTeam] = useState()
    const [loading, setLoading] = useState(false)
    const [eventNumber, setEventNumber] = useState()
    const [show50s, setShow50s] = useState(true)
    const [show100s, setShow100s] = useState(false)
    const [show200s, setShow200s] = useState(false)

    useEffect(() => {
        getResource(`/team/${team_id}`).then(team => {
            team.relay_events = {}
            for (const number in team.events) {
                const event = team.events[number]
                if (event.relay)
                    team.relay_events[number] = event
            }
            setTeam(team)
        })
    }, [team_id])

    if (!team)  return <ProgressBar animated now={100}/>

    function update_swimmers() {
        setLoading(true)
        const relay_events = team.relay_events
        postResource(`/team/${team_id}`, {team_id: team.id}).then(team => {
            team.relay_events = relay_events
            setTeam(team)
            setLoading(false)
        } )  
    }
    function update_times() {
        setLoading(true)
        const relay_events = team.relay_events
        putResource(`/team/${team_id}`, {team_id: team.id}).then(team => {
            team.relay_events = relay_events
            setTeam(team)
            setLoading(false)
        } )  
    }
    function in_event(swimmer) {
        if (!eventNumber) return true
        const event_number = parseInt(eventNumber)
        if (swimmer.events && swimmer.events[event_number] === false) return false
        let gender = team.events[event_number].gender
        if (gender === 'X') return true
        if (gender === 'W') gender = 'F'
        if (gender === swimmer.sex) return true
        return false
    }
    function select_swimmer(index) {
        //if (user)
        navigate(`/team/${team.id}/swimmer/${index}`)
    }
        
    return (
        <Container>
            <p>{ team.meet_dates }</p>
            <h4>
                <Link to={ `/meet/${team.meet_id}` } className="me-2">
                    <Button variant="info" size="sm"><FontAwesomeIcon icon={faReply}/></Button>
                </Link>
                { team.meet_name } <Badge variant="primary">{ team.course }</Badge>
            </h4>

            <legend>
                <FontAwesomeIcon icon={faFlagCheckered} className="gold me-2"/>
                { team.name }
                <DropdownButton title={ team.swimmers?.length + " Swimmers"} className="ms-3" style={{ display: 'inline' }}>
                    <Dropdown.Item onClick={update_swimmers}>
                        <FontAwesomeIcon icon={faSwimmer} className="me-2"/>
                        Update roster
                    </Dropdown.Item>
                    <Dropdown.Item onClick={update_times}>
                        <FontAwesomeIcon icon={faSync} className="me-2"/>
                        Update USMS times
                    </Dropdown.Item>
                </DropdownButton>
            </legend>
            <Row>
                <Col xs={7}>
                    <h5>Event:
                    <select value={ eventNumber }
                        onChange={ e => setEventNumber(parseInt(e.target.value))}>
                        <option value='0'>All events</option>
                        { Object.keys(team.relay_events).map(number =>
                            <option key={number} value={number}>
                                { team.relay_events[number].name }
                            </option>
                        ) }
                    </select>
                    </h5>
                </Col>
                <Col xs={5} className='text-end'>
                    Show times for:
                        <Button variant={ show50s ? 'warning' : 'outline-dark' }
                            onClick={ () => setShow50s(!show50s) }>50s</Button>
                        <Button variant={ show100s ? 'warning' : 'outline-dark' }
                            onClick={ () => setShow100s(!show100s) }>100s</Button>
                        <Button variant={ show200s ? 'warning' : 'outline-dark' }
                            onClick={ () => setShow200s(!show200s) }>200s</Button>
                </Col>
            </Row>
            { loading && <ProgressBar animated now={100}/> }

{/*}
<div className={`alert alert-dismissable alert-${alert.style}`} ng-repeat="alert in alerts">{ alert.body }</div>

<style>
.excluded td { background-color: #CCC !important; text-decoration: line-through }
.fa-check { color: #3276B1 }
.fa-times { color: #D2322D }
</style>
*/}
            <Table id="swimmers" className="table table-bordered table-striped table-list">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Club</th>
                        <th>S</th>
                        <th>Age</th>
                        { Object.keys(team.relay_events).filter(number => (!eventNumber || eventNumber === number))
                                .map(number => <th key={number}>{ number }</th> ) }
                        { show50s && STROKES.map(stroke => <th key={stroke}>50 {stroke}</th>) }
                        { show100s && STROKES.map(stroke => <th key={stroke}>100 {stroke}</th>) }
                        { show200s && <th>200 Free</th> }
                    </tr>
                </thead>
                <tbody>
                    { team.swimmers?.filter(in_event).map((swimmer, s) =>
                    <tr key={s} onClick={() => select_swimmer(s)} style={{ cursor: 'pointer' }}
                        className={ not_in_event(swimmer, eventNumber) ? 'excluded' : '' }>
                        <td>{ swimmer.name }</td>
                        <td>{ swimmer.club }</td>
                        <td>{ swimmer.sex }</td>
                        <td>{ swimmer.age }</td>
                        { Object.keys(team.relay_events).filter(number => (!eventNumber || eventNumber === number))
                                .map(number =>
                            <td key={number}>
                                { swimmer.events[number] === true && <FontAwesomeIcon icon={faCheck}/> }
                                { swimmer.events[number] === false && <FontAwesomeIcon icon={faTimes}/> }
                            </td>
                        ) }
                        { show50s && STROKES.map(stroke =>
                            <td key={stroke}>{ formatted_time(swimmer.times[`50 ${stroke}`]) }</td>
                        ) }
                        { show100s && STROKES.map(stroke =>
                            <td key={stroke}>{ formatted_time(swimmer.times[`100 ${stroke}`]) }</td>
                        ) }
                        { show200s && <td>{ formatted_time(swimmer.times['200 Free']) }</td> }
                    </tr>
                    ) }
                </tbody>
            </Table>
        </Container>
    )
}

export default TeamSwimmers