import React from 'react'
import { Link } from 'react-router-dom'

import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFlag, faStar } from '@fortawesome/free-regular-svg-icons'
import { faTrophy, faCloudDownloadAlt, faCogs, faCheck } from '@fortawesome/free-solid-svg-icons'

function Home() {
    const titleStyle = { color: '#06F', fontFamily: 'Arial Black, sans-serif',
                         margin: '20px 15px', textShadow: '1px 1px gold' }
    const cardStyle = { marginBottom: '20px', cursor: 'pointer',
                        '&:hover': { background: '#efefef', color: 'yellow' } }
    const iconStyle = { verticalAlign: 'text-top' }
    const h3Style = { marginTop: '20px' }

    return (
    <Container>
        <h3 className="text-center" style={titleStyle}>
            <i>The Mobile App for<br className="rwd-break"/> Masters Swimmers</i>
        </h3>

        <Link to="/meets">
        <Card style={cardStyle}>
            <Card.Body>
                <Row>
                    <Col xs={4} md={3} lg={2}>
                        <div className="touch-icon">
                            <div className="touch-icon-box gold-gradient">
                                <FontAwesomeIcon icon={faCalendarAlt} style={iconStyle}/>
                            </div>
                            <div className="touch-icon-label">Meets</div>
                        </div>
                    </Col>
                    <Col xs={8} md={9} lg={10}>
                        <h3 style={h3Style}>Schedule upcoming swim meets</h3>
                        <h3 style={h3Style}>Find the best relay teams</h3>
                    </Col>
                </Row>
                <Row><hr/></Row>
                <Row>
                    <p className="col-xs-12 col-sm-3">
                        <FontAwesomeIcon icon={faCloudDownloadAlt} className="me-1"/>
                        Select a meet and RECORD BREAK'R will automatically find the relay events and the swimmers.
                    </p>
                    <p className="col-xs-11 col-sm-3">
                        <FontAwesomeIcon icon={faCogs} className="me-1"/>
                        Generate all possible relay teams by age group for mens, womens and mixed relays.
                    </p>
                    <p className="col-xs-12 col-sm-3">
                        <FontAwesomeIcon icon={faCheck} className="me-1"/>
                        Choose the best teams, share them online and update all relays from your smartphone.
                    </p>
                    <p className="col-xs-11 col-sm-3">
                        <FontAwesomeIcon icon={faTrophy} className="me-1"/>
                        See how the relay times compare with FINA, USMS, Zone and Club records.
                    </p>
                </Row>
            </Card.Body>
        </Card>
        </Link>

        <Link to="/records">
        <Card style={cardStyle}>
            <Card.Body>
                <Row>
                    <Col xs={4} md={3} lg={2}>
                        <div className="touch-icon">
                            <div className="touch-icon-box gold-gradient">
                                <FontAwesomeIcon icon={faTrophy}/>
                            </div>
                            <div className="touch-icon-label">Records</div>
                        </div>
                    </Col>
                    <Col xs={8} md={9} lg={10}>
                        <h3 style={h3Style}>See all records on one page and filter<br className="rwd-break"/> by course, age group, stroke and more</h3>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </Link>

        <Link to="/swimmers">
        <Card style={cardStyle}>
            <Card.Body>
                <Row>
                    <Col xs={4} md={3} lg={2}>
                        <div className="touch-icon">
                            <div className="touch-icon-box gold-gradient">
                                <FontAwesomeIcon icon={faStar} style={iconStyle}/>
                            </div>
                            <div className="touch-icon-label">Swimmers</div>
                        </div>
                    </Col>
                    <Col xs={8} md={9} lg={10}>
                        <h3 style={h3Style}>Set your goals by comparing your times with current records</h3>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </Link>

        <Link to="/clubs">
        <Card style={cardStyle}>
            <Card.Body>
                <Row>
                    <Col xs={4} md={3} lg={2}>
                        <div className="touch-icon">
                            <div className="touch-icon-box gold-gradient">
                                <FontAwesomeIcon icon={faFlag} style={iconStyle}/>
                            </div>
                            <div className="touch-icon-label">Clubs</div>
                        </div>
                    </Col>
                    <Col xs={8} md={9} lg={10}>
                        <h3 style={h3Style}>FINA, USMS, Zone, LMSC, Club and Group records are automatically updated</h3>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </Link>
  
    </Container>
    )
}

export default Home