import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Modal from 'react-bootstrap/Modal'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faPlusCircle, faSpinner, faTimesCircle, faUser } from '@fortawesome/free-solid-svg-icons'

import { getResource } from '../util/resource'


function Meet() {
    const { meet_id } = useParams()
    const meet_params = meet_id.split('_')
    const [meet, setMeet] = useState()
    const [loading, setLoading] = useState(true)
    const [teams, setTeams] = useState([])
    const [loadingTeams, setLoadingTeams] = useState(true)
    const [modal, setModal] = useState(false)
    function closeModal() { setModal(false) }

    function delete_meet() {
        closeModal()
    }

    useEffect(() => {
        getResource('/meet/'+meet_id)
            .then(meet => {
                setMeet(meet)
                setLoading(false)
            })
        getResource('/teams/'+meet_id)
            .then(teams => {
                setTeams(teams)
                setLoadingTeams(false)
            })
    }, [meet_id])

    if (!meet)   return null

    return (
        <Container>
            { loading && <ProgressBar animated now={100}/> }

            <h4>{ meet.name } <Badge variant="primary">{ meet.course }</Badge></h4>
            <p>{ meet.dates }</p>

            <ButtonGroup>
                <Link to={`/meet/${meet.id }/swimmers`}>
                    <Button><FontAwesomeIcon icon={faUser} className="gold me-2"/>Swimmers</Button>
                </Link>
                <a target="_blank" rel="noopener noreferrer"
                    href={"https://www.clubassistant.com/club/meet_information.cfm?c=" + meet_params[0] + "&smid=" + meet_params[1] }>
                    <Button className='ms-1'><FontAwesomeIcon icon={faInfoCircle} className="gold me-2"/>Meet Information</Button>
                </a>
                { meet.is_Admin &&
                <Link to={`/meet/${meet.id }/event`}>
                    <Button className='ms-1'><FontAwesomeIcon icon={faPlusCircle} className="gold me-2"/>Add Event</Button>
                </Link>
                }
                { meet.is_Admin &&
                <Button onClick={() => setModal(true)} className='ms-1'>
                    <FontAwesomeIcon icon={faTimesCircle} className="gold me-2"/>Delete Meet
                </Button>
                }
            </ButtonGroup>

            <Modal show={modal} size="sm">
                <Modal.Header>
                    <Modal.Title>Delete this Meet?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={delete_meet}>OK</Button>
                </Modal.Footer>
            </Modal>

            <Card>
                <Card.Header>
                    { loadingTeams &&
                    <FontAwesomeIcon icon={faSpinner} spin className="gold me-2"/> }
                    Relay Teams
                </Card.Header>
                { !(loadingTeams || teams.length) &&
                <Card.Body>
                    There are no relay teams for this meet
                </Card.Body>
                }
                <ListGroup>
                    { teams.map(team =>
                    <ListGroupItem key={team.id}>
                        <Link to={`/team/${team.id}`}>
                            <Button>
                                <Badge variant="secondary">{ team.club }</Badge> { team.name }
                            </Button>
                        </Link>
                    </ListGroupItem>
                    ) }
                </ListGroup>
                <Card.Footer>
                    To create a relay team, click on the Swimmers button and choose a Club
                </Card.Footer>
            </Card>

<br/>
            <Card>
                <table>
                    <thead>
                        <tr>
                            <th>Events</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(meet.events).map(key =>
                            <tr key={key}>
                                <td>{ meet.events[key].name }</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Card>
        </Container>
    )
}

export default Meet
