import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

import { getResource } from '../util/resource'


function MeetsTable(props) {
    if (!props.meets) return null
    const id = props.title.toLowerCase().replaceAll(' ', '_')
    return (
        <Card className='mt-4'>
            <Card.Header className='blue-gradient'>{ props.title }</Card.Header>
            <ListGroup id={id}>
                { props.meets.map(meet =>
                    <ListGroup.Item style={{ padding: '8px' }} ng-click='select_meet(meet)' key={ meet.name }>
                        <Link to={'/meet/'+meet.id}>
                            { meet.name }<br/>
                            <small style={{color: 'black'}}>
                                <FontAwesomeIcon icon={faCalendarAlt} className='me-2' />{ meet.dates }
                            </small>
                        </Link>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </Card>
    )
}

function Meets() {
    const [meets, setMeets] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getResource('/meet')
            .then(res => {
                const meets = res.map(meet => {
                    const dates = meet.dates.split(' - ')
                    meet.date = new Date(dates[dates.length-1])
                    return meet
                })
                let yesterday = new Date()
                yesterday.setDate(yesterday.getDate() - 1)
                const upcoming = meets.filter(meet => meet.date > yesterday).sort((a, b) => a.date - b.date)
                const prior = meets.filter(meet => meet.date <= yesterday).sort((a, b) => b.date - a.date)
                setMeets({ upcoming, prior })
                setLoading(false)
            })
    }, [])

    return (
        <Container>
            <h3>
                <FontAwesomeIcon icon={faCalendarAlt} className='gold me-2'/>
                <span className='legend me-5'>Swim Meets</span>
                <Link to='/meets/upcoming'>
                    <Button className='gold-background' style={{ border: '#CCC', color: '#337ab7' }}>
                        <FontAwesomeIcon icon={faCloudDownloadAlt}/> Download upcoming meets
                    </Button>
                </Link>
                {/*
                <a class='btn btn-thick gold-background' href='#/meets/create'>
                    <FontAwesomeIcon icon={faPlusSquare}/> Add a Meet
                </a>
                */}
            </h3>

            { loading && <ProgressBar animated now={100}/> }

            <MeetsTable title='Upcoming Meets' meets={meets.upcoming}/>
            <MeetsTable title='Prior Meets' meets={meets.prior}/>
        </Container>
    )
}

export default Meets