export { formatted_time, time_since, region_variant,
         team_swimmers, relay_team_record, relay_age, relay_swimmer, stroke_time }

function formatted_time(decimal_time) {
    if (!decimal_time)  return decimal_time
    let hundredths = decimal_time % 100
    let seconds = Math.floor(decimal_time / 100)
    const minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    if (seconds < 10) seconds = '0' + seconds
    if (hundredths < 10) hundredths = '0' + hundredths
    const time = seconds + '.' + hundredths
    return minutes > 0 ? minutes + ':' + time : time
}

function time_since(timestamp) {
    var seconds = Math.floor((new Date() - timestamp) / 1000)
    var interval = Math.floor(seconds / 86400)
    if (interval > 1)
        return interval + " days"
    interval = Math.floor(seconds / 3600)
    if (interval > 1)
        return interval + " hours"
    interval = Math.floor(seconds / 60)
    if (interval > 1)
        return interval + " minutes"
    interval = Math.floor(seconds)
    return (interval > 1? interval : "a few") + " seconds"
}

// These functions are called by <Team> and <TeamEvent>
const REGION_VARIANTS = {FINA: 'info', USMS: 'danger', ZONE: 'warning', OREG: 'success'}
function region_variant(region) {
    return REGION_VARIANTS[region] || 'secondary'
}

const swimmers = {}     // lookup swimmers by usms_id
function team_swimmers(team) {
    for (const swimmer of team.swimmers)
        swimmers[swimmer.usms_id] = swimmer
}

function relay_team_record(relay_team) {
    if ( relay_team.record )
        return ` [${formatted_time(relay_team.record)} ${relay_team.region} record]`
}

function relay_age(usms_ids, course) {
    if (course === 'SCY') return ' '  // SCY uses youngest age
    let age = 0
    for (const usms_id of usms_ids)
        age += swimmers[usms_id].age
    return ' (' + age + ') '
}

function relay_swimmer(usms_id, stroke) {
    if (!usms_id) return 'usms_id: null'
    const swimmer = swimmers[usms_id]
    return `${swimmer.name} ${swimmer.sex} ${swimmer.age} ${stroke_time(usms_id, stroke)} _ ${stroke}`
}

function stroke_time(usms_id, event) {
    return formatted_time(swimmers[usms_id].times[event])
}
